<template>
  <v-card width="75%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. процессы ожидающие старта</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ProcessesTable :queryParams="{ status: 5 }">
        <template v-slot:name="{ process }">
          <router-link :to="'/manufacture/process/view?id=' + process.id">
            {{process.template.name}}
          </router-link>
        </template>
      </ProcessesTable>
    </v-card-text>
  </v-card>
</template>
<script>
import ProcessesTable from './components/ProcessesTable.vue'

export default {
  components: { ProcessesTable },
}
</script>
